import * as React from 'react';
import styles from "./index.module.scss";

function Blog() {
    return (
        <div className={styles.blog}>
            <div className={styles.blogTop}>
                <img src="/images/home/blog.png"/>
                <a href="https://blog.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                   target="_blank">
                    <button className={styles.button}>Browser all Articles</button>
                </a>
            </div>
            <div className={styles.blogBottom}>
                <div className={styles.blogBottomLeftMiddRight} data-aos="zoom-out">
                    <div className={styles.blogBottomLeftMiddRightImg}><img src="/images/home/blog1.png"/></div>
                    <div className={styles.blogBottomLeftMiddRightContent}>
                        <div className={styles.blogBottomLeftMiddRightContentFrontTitle}>
                            The Complete List of Banned Words In Midjourney You Need to Know
                        </div>
                        <div className={styles.blogBottomLeftMiddRightContentFrontDes}>Using a dataset of text-image
                            pairs, Midjourney AI is an art generator that generates artwork in response to textual
                            prompts. One must be...
                        </div>
                    </div>
                    <div className={styles.blogBottomLeftMiddRightFooter}>
                        <div style={{color: "#8F9BB7"}}>Jul 20, 2023</div>
                        <div style={{color: "#FFFFFF", display: 'flex'}}>
                            <a href="https://medium.com/easy-prompt/the-complete-list-of-banned-words-in-midjourney-you-need-to-know-12111a5bbf87"
                               style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">
                                <div>Read more</div>
                            </a>
                            <div><img src="/images/home/blogNext.png"/></div>

                        </div>
                    </div>
                </div>
                <div className={styles.blogBottomLeftMiddRight} data-aos="zoom-out">
                    <div className={styles.blogBottomLeftMiddRightImg}><img src="/images/home/blog2.png"/></div>
                    <div className={styles.blogBottomLeftMiddRightContent}>
                        <div className={styles.blogBottomLeftMiddRightContentFrontTitle}>
                            10 Tips for Crafting Effective Prompts for Midjourney
                        </div>
                        <div className={styles.blogBottomLeftMiddRightContentFrontDes}>ULearn how to create effective
                            prompts for Midjourney with these 10 valuable tips. BONUS: access free Midjourney Prompt
                            Generation...
                        </div>
                    </div>
                    <div className={styles.blogBottomLeftMiddRightFooter}>
                        <div style={{color: "#8F9BB7"}}>Jul 18, 2023</div>
                        <div style={{color: "#FFFFFF", display: 'flex'}}>
                            <a href="https://medium.com/easy-prompt/10-tips-for-crafting-effective-prompts-for-midjourney-aea3496d3345"
                               style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">
                                <div>Read more</div>
                            </a>
                            <div><img src="/images/home/blogNext.png"/></div>

                        </div>
                    </div>
                </div>
                <div className={styles.blogBottomLeftMiddRight} data-aos="zoom-out">
                    <div className={styles.blogBottomLeftMiddRightImg}><img src="/images/home/blog3.png"/></div>
                    <div className={styles.blogBottomLeftMiddRightContent}>
                        <div className={styles.blogBottomLeftMiddRightContentFrontTitle}>
                            The Ultimate chatGPT Prompts for Creating Irresistible Article Titles
                        </div>
                        <div className={styles.blogBottomLeftMiddRightContentFrontDes}>Unlocking killer article titles.
                            Use this chatGPT mega prompt — Title Generator GPT — — — — CONTEXT: You are Title Generator
                            GPT, a...
                        </div>
                    </div>
                    <div className={styles.blogBottomLeftMiddRightFooter}>
                        <div style={{color: "#8F9BB7"}}>May 31, 2023</div>
                        <div style={{color: "#FFFFFF", display: 'flex'}}>
                            <a href="https://medium.com/easy-prompt/the-ultimate-chatgpt-prompts-for-creating-irresistible-article-titles-dafb8a4583ed"
                               style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">
                                <div>Read more</div>
                            </a>
                            <div><img src="/images/home/blogNext.png"/></div>

                        </div>
                    </div>
                </div>

                {/*<div className={styles.blogBottomLeftMiddRight} data-aos="zoom-out">*/}
                {/*    <div className={styles.blogBottomLeftMiddRightImg}><img src="/images/home/blog3.png"/></div>*/}
                {/*    <div className={styles.blogBottomLeftMiddRightContent}>*/}
                {/*        <div className={styles.blogBottomLeftMiddRightContentFrontTitle}>*/}
                {/*            The Best Email Encryption Services for 2023*/}
                {/*        </div>*/}
                {/*        <div className={styles.blogBottomLeftMiddRightContentFrontDes}>Dorttitor mattis pharetra sit id*/}
                {/*            viverra. Vivamus mauris augue pharetra cras turpis faucibus elit urna.*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className={styles.blogBottomLeftMiddRightFooter}>*/}
                {/*        <div style={{color: "#8F9BB7"}}>February 8, 2023</div>*/}
                {/*        <div style={{color: "#FFFFFF", display: 'flex'}}>*/}
                {/*            <div>Read more</div>*/}
                {/*            <div><img src="/images/home/blogNext.png"/></div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Blog;
