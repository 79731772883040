import * as React from 'react';
import styles from "./index.module.scss";

function Banner() {
    return (
        <div className={styles.banner} data-aos="fade-up"
             data-aos-duration="1000">
            <div className={styles.bannerTitle}>Smarter AI with Better Prompts</div>
            <div>
                <div className={styles.bannerDescription}>Say goodbye to ineffective prompts and unlock the full
                    potential of AI.
                </div>
                <div className={styles.bannerDescription}>With EasyPrompt, you can use AI tools more effectively and
                    efficiently,
                </div>
                <div className={styles.bannerDescription}>no matter your level of expertise.</div>
            </div>
            <div className={styles.button}>
                <a href="https://t.me/gpt_prompter_bot?start=homepage" style={{textDecoration: "none", color: "#8F9BB7"}}
                   target="_blank">
                    <button className={styles.bannerButton}>AI Chatbot
                    </button>
                </a>
                <a href="https://library.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                   target="_blank">
                    <button className={styles.bannerButton}>Prompt Library
                    </button>
                </a>
            </div>
        </div>
    );
}

export default Banner;
