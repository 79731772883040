import * as React from 'react';
import {Link} from "react-router-dom";

import styles from "./index.module.scss";

function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.footerContainer}>
                <div className={styles.footerContainerTop}>
                    <div className={styles.footerContainerTopLeft}>
                        <div style={{
                            paddingBottom: 35,
                        }}>
                            <img style={{
                                maxWidth: 50,
                            }} src="/images/logo_not_bg.png"/>
                        </div>
                        <div className={styles.content}>
                            Smarter AI with Better Prompts
                        </div>
                    </div>
                    <div className={styles.footerContainerTopMidd}>
                        <div className={styles.footerContainerTopMiddLeftRight}>
                            <div style={{
                                fontWeight: 700,
                                fontSize: 20,
                            }}>Support
                            </div>
                            <div><a href="/"
                                    style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">Home</a></div>
                            <div><a href="https://docs.easyprompt.xyz/"
                                    style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank"> About Us</a>
                            </div>
                            <div><a href="https://blog.easyprompt.xyz/"
                                    style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">Blog</a></div>
                            {/*<div><a href="/"*/}
                            {/*        style={{textDecoration: "none", color: "#8F9BB7"}}>FAQ</a></div>*/}
                            {/*<div>User Guide</div>*/}
                            <div><a href="mailto:easyprompt@gmail.com"
                                    style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">Contact</a></div>
                        </div>
                        <div className={styles.footerContainerTopMiddLeftRight}>
                            <div style={{
                                fontWeight: 700,
                                fontSize: 20,
                            }}>Product
                            </div>
                            <div><a href="https://t.me/gpt_prompter_bot?start=homepage"
                                    style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">AI Telegram
                                bot</a></div>
                            <div>
                                <a href="https://library.easyprompt.xyz/"
                                   style={{textDecoration: "none", color: "#8F9BB7"}} target="_blank">Prompt Library</a>
                            </div>
                            <div><Link to="/coming-soon"
                                       style={{textDecoration: "none", color: "#8F9BB7"}}>GPT Customizer</Link>
                            </div>
                            <div><Link to="/coming-soon"
                                       style={{textDecoration: "none", color: "#8F9BB7"}}>Integration</Link></div>
                        </div>
                    </div>
                    {/*<div className={styles.footerContainerTopRight}>*/}
                    {/*<div className={styles.footerContainerTopRightForm}>*/}
                    {/*    <div className={styles.footerContainerTopRightFormTitle}>Subscribe to our newsletter</div>*/}
                    {/*    <div className={styles.footerContainerTopRightFormInput}>*/}
                    {/*        <input className={styles.input} placeholder="Enter your email"/>*/}
                    {/*    </div>*/}
                    {/*    <div className={styles.footerContainerTopRightFormInput}>*/}
                    {/*        <button className={styles.button}>Subscribe</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                </div>
                <div className={styles.footerContainerBottom}>
                    <div style={{color: "#8F9BB7"}}>
                        Copyright © 2023 by EasyPrompt
                    </div>
                    <div style={{display: "flex", gap: 25}}>
                        <div><a href="https://t.me/easypromptHQ"><img src="/images/home/telegram.png" target="_blank"/></a>
                        </div>
                        <div><a href="https://twitter.com/easyprompthq"><img src="/images/home/twitter.png"
                                                                             target="_blank"/></a></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
