import React, {useContext, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Loading from "react-loading";

import './App.css';
import Header from "../src/pages/home/header";
import Banner from "../src/pages/home/banner";
import Feature from "../src/pages/home/feature";
import UseAndEarn from "../src/pages/home/use.and.earn";
import Blog from "../src/pages/home/blog";
import Footer from "../src/pages/home/footer";
import ComingSoon from "../src/components/comingsoon";

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 4000);
    }, []);

    return (
        <>
            {isLoading ? (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#0E1330",
                    minHeight: "100vh",
                }}>
                    <Loading type="spin" color="#282D45"/>
                </div>

            ) : (
                <Router>
                    <ScrollToTop/>
                    <div>
                        <div className="App">
                            <Header></Header>
                            <Switch>
                                <Route path="/coming-soon">
                                    <ComingSoon></ComingSoon>
                                </Route>
                                <Route path="/">
                                    <Banner></Banner>
                                    <Feature></Feature>
                                    {/*<UseAndEarn></UseAndEarn>*/}
                                    <Blog></Blog>
                                </Route>
                            </Switch>
                            <Footer></Footer>
                        </div>
                    </div>
                </Router>
            )}
        </>
        // <Router>
        //     <div>
        //         <div className="App">
        //             <Header></Header>
        //             <Switch>
        //                 <Route path="/coming-soon">
        //                     <ComingSoon></ComingSoon>
        //                 </Route>
        //                 <Route path="/">
        //                     <Banner></Banner>
        //                     <Feature></Feature>
        //                     <UseAndEarn></UseAndEarn>
        //                     <Blog></Blog>
        //                 </Route>
        //             </Switch>
        //             <Footer></Footer>
        //         </div>
        //     </div>
        // </Router>

    );
}

export default App;
