import React, {useState, useEffect} from "react";
import styles from "./index.module.scss";
import {IconButton, MenuItem, Menu} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from "@material-ui/core/styles";

import {Link} from "react-router-dom";

function Header() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return <div className="container">{isMobile ? <MobileView/> : <DesktopView/>}</div>;
}

function DesktopView() {
    const [showDropdown, setShowDropdown] = React.useState(false);

    return (
        <div className={styles.header}>
            <div className={styles.headerContainer}>
                <div className={styles.headerContainerLeft}>
                    <Link to="/" style={{textDecoration: "none", color: "#8F9BB7"}}>
                        <img
                            style={{
                                maxWidth: 50,
                            }}
                            src="/images/logo_not_bg.png"
                        />
                    </Link>
                </div>
                <div className={styles.headerContainerMidd}>
                    <div>
                        <Link to="/" style={{textDecoration: "none", color: "#8F9BB7"}}>
                            Home
                        </Link>
                    </div>
                    <div className={styles.dropdown}>
                        <button className={styles.dropbtn}>
                            Products
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={styles.dropdownContent}>
                            <a href="https://t.me/gpt_prompter_bot?start=homepage"
                               style={{textDecoration: "none", color: "#8F9BB7"}}
                               target="_blank">
                                AI Chatbot
                            </a>
                            <a href="https://library.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                               target="_blank">
                                Prompt Library
                            </a>
                            <Link to="/coming-soon" style={{textDecoration: "none", color: "#8F9BB7"}}>
                                GPT Customizer
                            </Link>
                        </div>
                    </div>
                    <div>
                        <a href="https://docs.easyprompt.xyz" style={{textDecoration: "none", color: "#8F9BB7"}}
                           target="_blank">
                            Docs
                        </a>
                    </div>
                    <div>
                        <a href="https://blog.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                           target="_blank">
                            Blog
                        </a>
                    </div>
                </div>
                <div className={styles.headerContainerRight}>
                    <div>
                        <a href="https://t.me/easypromptHQ" target="_blank">
                            <img src="/images/home/telegram.png"/>
                        </a>
                    </div>
                    <div>
                        <a href="https://twitter.com/easyprompthq" target="_blank">
                            <img src="/images/home/twitter.png"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MobileView() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [subMenuOpen, setSubMenuOpen] = useState(false);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSubTabClick = (event) => {
        setSubMenuAnchorEl(event.currentTarget);
        setSubMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubMenuAnchorEl(null);
        setSubMenuOpen(false);
    };

    return (
        <div className={styles.header}>
            <IconButton onClick={handleMenuClick} edge="start" style={{color: "white"}} aria-label="menu">
                <MenuIcon/>
            </IconButton>
            <Menu id={styles["menuMobile"]} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
                  onClose={handleClose} classes={{paper: classes.customMenu}}>
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <Link to="/" style={{textDecoration: "none", color: "#8F9BB7"}}>
                        Home
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <a href="https://docs.easyprompt.xyz" style={{textDecoration: "none", color: "#8F9BB7"}}
                       target="_blank">
                        Docs
                    </a>
                </MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <a href="https://blog.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                       target="_blank">
                        Blog
                    </a>
                </MenuItem>
                <MenuItem onClick={handleSubTabClick} className={classes.menuItem}>
                    Products
                </MenuItem>
            </Menu>
            <Menu className={styles.subMenuMobile} anchorEl={subMenuAnchorEl} keepMounted open={subMenuOpen}
                  onClose={handleClose} classes={{paper: classes.customMenu}}>
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <a href="https://t.me/gpt_prompter_bot?start=homepage"
                       style={{textDecoration: "none", color: "#8F9BB7"}}
                       target="_blank">
                        AI Chatbot
                    </a>
                </MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <a href="https://library.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                       target="_blank">
                        Prompt Library
                    </a>
                </MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuItem}>
                    <Link to="/coming-soon" style={{textDecoration: "none", color: "#8F9BB7"}}>
                        GPT Customizer
                    </Link>
                </MenuItem>
            </Menu>
            <div>
                <Link to="/" style={{textDecoration: "none"}}>
                    <img
                        style={{
                            maxWidth: 45,
                        }}
                        src={"/images/logo_not_bg.png"}
                    />
                </Link>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    menuItem: {
        width: 170,
        zIndex: 11,
        color: "#8F9BB7",
        "&:hover": {
            backgroundColor: "#8F9BB7",
            color: "white",
        },
    },
    customMenu: {
        backgroundColor: "#0E1330",
    },
});

export default Header;
