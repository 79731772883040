import * as React from 'react';
import styles from "./index.module.scss";

function ComingSoon() {
    return (
        <div className={styles.comingsoon}>
            Coming soon
        </div>
    );
}

export default ComingSoon;
