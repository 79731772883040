import styles from "./index.module.scss";
import {Link} from 'react-router-dom';
import React, { useRef } from 'react';

function Feature() {
    const videoRef = useRef(null);

    const handleVideoEnd = () => {
        // Bắt sự kiện khi video kết thúc và chạy lại nó
        videoRef.current.play();
    };

    return (
        <div className={styles.feature}>
            <div className={styles.content1}>
                <div className={styles.content1Left} data-aos="zoom-in">
                    <div className={styles.content1LeftTitle}>
                        ChatGPT & Midjourney for Telegram
                    </div>
                    <div className={styles.content1LeftDes}>GPT Prompter Bot allows you to access ChatGPT and Midjourney, to generate text and images right on Telegram.

                    </div>
                    <div>
                        <a href="https://t.me/gpt_prompter_bot?start=homepage" style={{textDecoration: "none", color: "#8F9BB7"}}
                           target="_blank">
                            <button className={styles.button}>Start Chatting</button>
                        </a>

                    </div>

                </div>
                <div className={styles.content1Right} data-aos="fade-down">
                    <div className={styles.content1RightLeft}>
                        <img className={styles.content1RightLeftImg} src="/images/home/mobie.png"/>
                        {/*<video ref={videoRef} autoPlay>*/}
                        {/*    <source src="/images/home/video1.mp4" type="video/mp4" />*/}
                        {/*    Your browser does not support the video tag.*/}
                        {/*</video>*/}
                    </div>
                    <div className={styles.content1RightRight}>
                        <div className={styles.content1RightRightIconAndText}>
                            <div><img className={styles.content1RightRightIcon} src="/images/home/free.png"/></div>
                            <div className={styles.content1RightRightText}>Fast & Convenient</div>
                        </div>
                        <div className={styles.content1RightRightIconAndText}>
                            <div><img className={styles.content1RightRightIcon} src="/images/home/avatar.png"/></div>
                            <div className={styles.content1RightRightText}>Easy to use</div>
                        </div>
                        <div className={styles.content1RightRightIconAndText}>
                            <div><img className={styles.content1RightRightIcon} src="/images/home/chart.png"/></div>
                            <div className={styles.content1RightRightText}>Smarter AI responses</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.content2}>
                <div className={styles.content2Left} data-aos="fade-down">
                    <div className={styles.content2LeftTop}>
                        <img className={styles.content2LeftTopImg} src="/images/home/laptop.png"/>
                    </div>
                    <div className={styles.content2LeftBottom}>
                        <div className={styles.content2LeftBottomTitle}>GPT Customizer</div>
                        <div className={styles.content2LeftBottomDes}>Build ChatGPT-like Chatbots With Customized Knowledge for Your Business.
                        </div>
                    </div>
                    <div className={styles.content2LeftBottomButton}>
                        <Link to="/coming-soon" style={{textDecoration: "none", color: "#8F9BB7"}}>
                            <button className={styles.button}>Coming Soon</button>
                        </Link>

                    </div>
                </div>
                <div className={styles.content2Right} data-aos="fade-down">
                    <div className={styles.content2RightTop}>
                        <div className={styles.content2RightTopTitle}>Prompt Library</div>
                        <div className={styles.content2RightTopDes}>
                            Access a diverse collection of ChatGPT prompts on EasyPrompt Library. Add more, bookmark your favorites, and discover the best ones!
                        </div>

                    </div>
                    <div className={styles.content2RightTopButton}>
                        {/*<Link to="/coming-soon" style={{textDecoration: "none", color: "#8F9BB7"}}>*/}
                        {/*    <button className={styles.button}>Try Now</button>*/}
                        {/*</Link>*/}
                        <a href="https://library.easyprompt.xyz/" style={{textDecoration: "none", color: "#8F9BB7"}}
                           target="_blank">
                            <button className={styles.button}>Explore</button>
                        </a>

                    </div>
                    <div className={styles.content2RightBottom}>
                        <img className={styles.content2RightBottomImg}
                             src="/images/home/prompt_lib.png"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feature;
