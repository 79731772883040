import * as React from 'react';
import styles from "./index.module.scss";

function UseAndEarn() {
    return (
        <div className={styles.useAndEarn}>
            <div className={styles.useAndEarnTop}>
                Use and Earn
            </div>
            <div className={styles.useAndEarnBottom}>
                <div className={styles.useAndEarnBottomLeftMiddRight} data-aos="fade-zoom-in"
                     data-aos-easing="ease-in-back"
                     data-aos-delay="300"
                     data-aos-offset="0">
                    <div className={styles.useAndEarnBottomLeftMiddRightImg}><img
                        src="/images/home/use_and_earn_1.png"/></div>
                    <div className={styles.useAndEarnBottomLeftMiddRightTitle}>ChatGPT for Telegram</div>
                    <div className={styles.useAndEarnBottomLeftMiddRightDes}>Our AI-powered Telegram chatbot allows you
                        to
                        generate effective responses without the need for a ChatGPT account. It's fast, easy, and free
                        to use. Plus, you can share your results with
                        others, making collaboration easy and efficient.
                    </div>
                </div>
                <div className={styles.useAndEarnBottomLeftMiddRight} data-aos="fade-zoom-in"
                     data-aos-easing="ease-in-back"
                     data-aos-delay="300"
                     data-aos-offset="0">
                    <div className={styles.useAndEarnBottomLeftMiddRightImg}>
                        <img
                            src="/images/home/use_and_earn_2.png"/></div>
                    <div className={styles.useAndEarnBottomLeftMiddRightTitle}>Prompt Marketplace</div>
                    <div className={styles.useAndEarnBottomLeftMiddRightDes}>At EasyPrompt Marketplace, you can browse
                        and purchase quality prompts that have been proven to generate the best results. And if you have
                        prompts to sell, you can do so here and earn money from your expertise.
                    </div>
                </div>
                <div className={styles.useAndEarnBottomLeftMiddRight} data-aos="fade-zoom-in"
                     data-aos-easing="ease-in-back"
                     data-aos-delay="300"
                     data-aos-offset="0">
                    <div className={styles.useAndEarnBottomLeftMiddRightImg}><img
                        src="/images/home/use_and_earn_3.png"/></div>
                    <div className={styles.useAndEarnBottomLeftMiddRightTitle}>Easy Integration</div>
                    <div className={styles.useAndEarnBottomLeftMiddRightDes}>EasyPrompt's integration feature is a
                        game-changer. With this feature, you can connect AI tools across other apps, like Zapier/IFTTT,
                        making it easy to automate tasks and streamline your workflow.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UseAndEarn;
